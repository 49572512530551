<template>
  <div>
    <air-register />
  </div>
</template>
<script>
import AirRegister from '@/components/system/Auth/Register'
export default {
  components: {
    AirRegister,
  },
}
</script>
